import * as React from "react";
import { login as apiLogin, LoginData } from "@/apis/login";

export interface AuthContext {
  isAuthenticated: boolean;
  login: (agentId: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  user: string | null;
}

const AuthContext = React.createContext<AuthContext | null>(null);

const key = "rc-agent-creation";

function getStoredUser() {
  const storedData = localStorage.getItem(key);
  return storedData ? JSON.parse(storedData).token : null;
}

function setStoredUser(token: string | null) {
  if (token) {
    localStorage.setItem(key, JSON.stringify({ token }));
  } else {
    localStorage.removeItem(key);
  }
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = React.useState<string | null>(getStoredUser());
  const isAuthenticated = !!user;

  const logout = React.useCallback(async () => {
    setStoredUser(null);
    setUser(null);
    localStorage.removeItem(key);
  }, []);

  const login = React.useCallback(async (agentId: string, password: string) => {
    try {
      const loginData: LoginData = { agent_id: agentId, password };
      const response = await apiLogin(loginData);

      if (response?.status) {
        setStoredUser(response.data.Authorization);
        setUser(response.data.Authorization);
      } else {
        throw new Error(response?.message || "Login failed");
      }
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message);
      }
      throw new Error("An unexpected error occurred");
    }
  }, []);

  React.useEffect(() => {
    setUser(getStoredUser());
  }, []);

  React.useEffect(() => {
    // Handle localStorage changes (for cross-tab sync and API responses)
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === key && !event.newValue) {
        // If token is removed from localStorage (e.g., due to a 401/403)
        logout(); // Trigger logout
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [logout]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export function useAuth() {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}
