import { getErrorMessage } from "@/utils";
import { summon } from "@/utils/summon";
import { z } from "zod";

export const LoginSchema = z.object({
	agent_id: z
		.string()
		.min(1, { message: "Agent ID is required" })
		.regex(/^[0-9]+$/, { message: "Agent ID must be a number" })
		.length(10, { message: "Agent ID must be 10 characters long" }),
	password: z.string().min(1, "Password is required"),
});

export type LoginData = z.infer<typeof LoginSchema>;

type ReturnedValue = {
	data: {
		Authorization: string;
	};
	message: string;
	status: boolean;
};

export const login = async (data: LoginData): Promise<ReturnedValue | undefined> => {
	try {
		const response = await summon.post<ReturnedValue>(
			`${import.meta.env.VITE_APP_PILOT_URL}/api/auth/localLogin`,
			data,
		);
		localStorage.setItem(
			"rc-agent-creation",
			JSON.stringify({ token: response.data.Authorization }),
		);
		return response;
	} catch (error) {
		throw new Error(getErrorMessage(error));
	}
};
