import { createFileRoute, Outlet, redirect } from "@tanstack/react-router";
// import { useEffect } from "react";

const fallback = "/welcome" as const;

export const Route = createFileRoute("/_auth")({
	beforeLoad: ({ context, search }) => {
		// @ts-expect-error auth
		if (context.auth.isAuthenticated) {
			// @ts-expect-error auth
			throw redirect({ to: search.redirect || fallback });
		}
	},
	component: AuthLayout,
});

export default function AuthLayout() {
	// const token = JSON.parse(localStorage.getItem("rc-agent-creation") as string) as {
	// 	token: string;
	// };
	// const navigate = useNavigate();
	// useEffect(() => {
	// 	if (token) {
	// 		navigate({ to: "/welcome" });
	// 	}
	// }, [token, navigate]);
	return <Outlet />;
}
