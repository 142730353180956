import { FormEvent } from "react";
import { useNavigate } from "@tanstack/react-router";
import {
  createOrReactivateUser,
  UserDetailsData,
  UserDetailsSchema,
} from "@/apis/createOrReactivateUser";
import { Errors } from "@/utils/types";
import { useFormUtils } from "@/hooks/useFormUtils";
import { Button, Input } from "@/components";
import { Toaster } from "@/components/toaster";

type State = {
  formData: UserDetailsData;
  errors: Errors | null;
  isSubmitting: boolean;
};

const initialState: State = {
  formData: {
    bvn: "",
    nin: "",
  },
  errors: null,
  isSubmitting: false,
};

export default function CreateOrReactivateUser({ token }: { token: string }) {
  const { handleSubmit, handleBlur, handleChange, state } = useFormUtils(
    UserDetailsSchema,
    initialState,
  );

  const navigate = useNavigate();

  const submit = async (event: FormEvent<HTMLFormElement>) => {
    await handleSubmit(event, async (formData) => {
      const data = await createOrReactivateUser(formData, token);

      await navigate({
        to: "/liveness-check",
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        state(prev: any) {
          return { ...prev, userData: data };
        },
      });
    });
  };

  return (
    <>
      {state.serverError && (
        <Toaster key={Math.random()} type={"error"} message={state.serverError} />
      )}
      <form onSubmit={submit} className="pt-8 w-[300px]">
        <Input
          label="BVN"
          name="bvn"
          type="text"
          placeholder="Please enter your BVN"
          onChange={handleChange}
          onBlur={handleBlur}
          error={(state.errors as Errors)?.bvn}
        />
        <Input
          label="NIN"
          name="nin"
          type="text"
          placeholder="Please enter your NIN"
          onChange={handleChange}
          onBlur={handleBlur}
          error={(state.errors as Errors)?.nin}
        />
        <Button fullWidth isLoading={state.isSubmitting} type="submit">
          Submit
        </Button>
      </form>
    </>
  );
}
