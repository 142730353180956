import { getErrorMessage } from "@/utils";
import { summon } from "@/utils/summon";
import { z } from "zod";

export const PatientDetailsSchema = z.object({
	walletId: z
		.string({ required_error: "WalletID is required" })
		.regex(/^[0-9]+$/, { message: "WalletID must be a number" }),
	phoneNumber: z
		.string({ required_error: "Phone number is required" })
		.regex(/^[0-9]+$/, { message: "Please enter a valid phone number" })
		.max(11, { message: "Phone number must be 11 digits" }),
	terminalId: z
		.string({ required_error: "Hopsital is required" })
		.min(2, { message: "Please enter valid Hospital" }),
	patientId: z
		.string({ required_error: "PatientID is required" })
		.min(2, { message: "Please enter valid patient ID" }),
});

export type PetientDetailsData = z.infer<typeof PatientDetailsSchema>;

export type PetientDetailReturnedValue = {
	status: boolean;
	message: string;
	data: string;
};

export const LinkWallet = async (
	data: PetientDetailsData,
	token: string,
): Promise<PetientDetailReturnedValue | undefined> => {
	try {
		const response = await summon.post<PetientDetailReturnedValue>(
			`${import.meta.env.VITE_APP_PILOT_URL}/api/auth/hospital/patient/wallet/link`,
			data,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);

		return response;
	} catch (error) {
		throw new Error(getErrorMessage(error));
	}
};
