import { cva } from "class-variance-authority";

export const buttonVariants = cva(
	"inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
	{
		variants: {
			variant: {
				default:
					"bg-[hsl(18,92%,65%)] text-white hover:bg-[hsl(18,92%,60%)] active:bg-[hsl(18,92%,45%)]",
				destructive: "bg-red-600 text-white hover:bg-red-700",
				outline:
					"border border-gray-300 bg-white text-gray-700 hover:bg-gray-100 hover:text-gray-900",
				secondary: "bg-gray-200 text-gray-900 hover:bg-gray-300",
				ghost: "hover:bg-gray-100 hover:text-gray-900",
				link: "text-blue-600 underline-offset-4 hover:underline",
			},
			size: {
				default: "h-10 px-4 py-2",
				sm: "h-9 rounded-md px-3",
				lg: "h-11 rounded-md px-8",
				icon: "h-10 w-10",
			},
			fullWidth: {
				true: "w-full",
			},
			isLoading: {
				true: "opacity-70 cursor-not-allowed",
			},
		},
		defaultVariants: {
			variant: "default",
			size: "default",
			fullWidth: false,
			isLoading: false,
		},
	},
);
