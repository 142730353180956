import React, { useState, useEffect, useMemo } from "react";
import { DangerIcon, InfoIcon, SuccessIcon } from "../svg";

export type ToastType = "success" | "error" | "warning" | "info";

export interface ToasterProps {
  message?: string;
  type: ToastType;
  duration?: number;
}

export const Toaster: React.FC<ToasterProps> = ({ message, type, duration = 5000 }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (message) {
      setShow(true);
      const timer = setTimeout(() => setShow(false), duration);
      return () => clearTimeout(timer);
    }
  }, [message, duration]);

  const iconMap = useMemo(
    () => ({
      error: <DangerIcon />,
      success: <SuccessIcon />,
      info: <InfoIcon />,
      warning: <DangerIcon />,
    }),
    [],
  );

  const colorMap = useMemo(
    () => ({
      error: "border-red-300 bg-red-100 text-red-900",
      success: "border-green-300 bg-green-100 text-green-900",
      info: "border-blue-300 bg-blue-100 text-blue-900",
      warning: "border-orange-300 bg-orange-100 text-orange-900",
    }),
    [],
  );

  if (!show) return null;

  return (
    <div className="fixed inset-x-0 top-0 z-50 flex items-end justify-center px-4 py-6 pointer-events-none">
      <div
        className={`p-4 w-full md:w-80 lg:w-96 rounded-md shadow-small border-2 ${colorMap[type]}`}
      >
        <div className="flex gap-4">
          {iconMap[type]}
          <div>
            <p className="font-bold capitalize text-base">{type}</p>
            <p className="text-sm">{message}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

Toaster.displayName = "Toaster";
