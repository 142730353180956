import * as React from "react";

type SlotProps = {
  children: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} & React.ComponentPropsWithoutRef<any>;

export const Slot = React.forwardRef<HTMLElement, SlotProps>(({ children, ...props }, ref) => {
  if (React.isValidElement(children)) {
    // @ts-expect-error - TSCONVERSION
    return React.cloneElement(children, { ...props, ref });
  }
  return children;
});

Slot.displayName = "Slot";
