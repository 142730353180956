import { UserDetailReturnedValue } from "@/apis/createOrReactivateUser";
import { submitVerification } from "@/apis/livenessCheck";
import {
  SET_CAPTURED_IMAGE,
  SET_SERVER_ERROR,
  SET_SUBMITTING,
  SET_SUCCESS,
} from "@/states/livenessCheckState/actionCreators";
import livenessCheckReducer, { initialState } from "@/states/livenessCheckState/reducer";
import { useCallback, useReducer, useRef } from "react";
import Webcam from "react-webcam";

export const useLivenessCheck = (
  userData: UserDetailReturnedValue,
  video: string | undefined | null,
  token: string,
) => {
  const [state, dispatch] = useReducer(livenessCheckReducer, initialState);
  const webcamRef = useRef<Webcam>(null);
  const submit = useCallback(
    async (imageSrc: string, video: string, liveCapture: string) => {
      return await submitVerification(imageSrc, video, liveCapture, token);
    },
    [token],
  );

  const handleCaptureAndSubmit = useCallback(async () => {
    const imageSrc = webcamRef?.current?.getScreenshot() ?? null;
    dispatch({ type: SET_CAPTURED_IMAGE, payload: imageSrc });
    if (!imageSrc) {
      return;
    }

    if (!video) {
      return;
    }

    try {
      dispatch({ type: SET_SUBMITTING, payload: true });
      dispatch({ type: SET_SERVER_ERROR, payload: "" });
      dispatch({ type: SET_SUCCESS, payload: "" });
      const data = await submit(imageSrc, video, userData?.info?.liveCapture);
      console.log("success data", data?.message);
      // @ts-expect-error - TSCONVERSION
      dispatch({ type: SET_SUCCESS, payload: data.message });
    } catch (error) {
      console.log({ error });
      dispatch({
        type: SET_SERVER_ERROR,
        payload: (error as Error).message ? (error as Error).message : "Something went wrong",
      });
    } finally {
      dispatch({ type: SET_SUBMITTING, payload: false });
    }
  }, [video, submit, userData?.info?.liveCapture, webcamRef]);

  return { state, dispatch, handleCaptureAndSubmit, webcamRef };
};
