import { Errors, FormData } from "@/utils/types";

export const SET_FORM_DATA = "SET_FORM_DATA";
export const SET_ERRORS = "SET_ERRORS";
export const SET_SUBMITTING = "SET_SUBMITTING";
export const SET_SERVER_ERROR = "SET_SERVER_ERROR";

export type Action<T> =
	| { type: typeof SET_FORM_DATA; payload: T }
	| { type: typeof SET_ERRORS; payload: Errors | null }
	| { type: typeof SET_SERVER_ERROR; payload: string | null }
	| { type: typeof SET_SUBMITTING; payload: boolean };

export const setSubmitting = <T extends FormData>(isSubmitting: boolean): Action<T> => ({
	type: SET_SUBMITTING,
	payload: isSubmitting,
});

export const setErrors = <T extends FormData>(errors: Errors | null): Action<T> => ({
	type: SET_ERRORS,
	payload: errors,
});

export const setFormData = <T extends FormData>(formData: T): Action<T> => ({
	type: SET_FORM_DATA,
	payload: formData,
});

export const setServerError = <T extends FormData>(serverError: string | null): Action<T> => ({
	type: SET_SERVER_ERROR,
	payload: serverError,
});
