import React from "react";
import { type VariantProps } from "class-variance-authority";

import { Slot } from "@/components/slot";

import { buttonVariants } from "./button.styles";
import { SpinnerIcon } from "../svg";
import { cn } from "@/utils";

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
	asChild?: boolean;
	fullWidth?: boolean;
	isLoading?: boolean;
} & VariantProps<typeof buttonVariants>;

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	(
		{ className, variant, size, asChild = false, fullWidth, isLoading, children, ...props },
		ref,
	) => {
		const Comp = asChild ? Slot : "button";
		return (
			<Comp
				className={cn(buttonVariants({ variant, size, fullWidth, isLoading, className }))}
				ref={ref}
				disabled={isLoading}
				{...props}
			>
				{isLoading ? (
					<>
						<SpinnerIcon className="mr-2" />
						<span className="sr-only">Loading</span>
					</>
				) : (
					children
				)}
			</Comp>
		);
	},
);

Button.displayName = "Button";

export { Button };
