const defaultHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
} as const;

const handleData = async <T>(response: Response): Promise<T> => {
  if (!response.ok) {
    const error = await response.json().catch(() => ({}));
    const errorMessage = error.error || error.message || response.statusText;
    if (response.status === 401 || response.status === 403) {
      // remove token
      localStorage.removeItem("rc-agent-creation");
      window.location.reload();
    }
    throw new Error(errorMessage);
  }
  return await response.json();
};

const fetchRequest = async <T>(url: string, options: RequestInit = {}): Promise<T> => {
  const config = {
    method: options.method || "GET",
    headers: {
      ...defaultHeaders,
      ...options.headers,
    },
    body: options.body ? JSON.stringify(options.body) : undefined,
  };
  const response = await fetch(url, config);
  return await handleData<T>(response);
};

export const summon = {
  get: <T>(url: string, options?: RequestInit) => fetchRequest<T>(url, options),
  post: <T>(
    url: string,
    body?: unknown,
    options?: RequestInit, // @ts-expect-error - TSCONVERSION
  ) => fetchRequest<T>(url, { method: "POST", body, ...options }),
  put: <T>(
    url: string,
    body?: unknown,
    options?: RequestInit, // @ts-expect-error - TSCONVERSION
  ) => fetchRequest<T>(url, { method: "PUT", body, ...options }),
  patch: <T>(
    url: string,
    body?: unknown,
    options?: RequestInit, // @ts-expect-error - TSCONVERSION
  ) => fetchRequest<T>(url, { method: "PATCH", body, ...options }),
  delete: <T>(url: string, options?: RequestInit) =>
    fetchRequest<T>(url, { method: "DELETE", ...options }),
};
