import React from "react";
import { Button } from "@/components";

type Props = {
  onGoBack: () => void;
  message?: string;
};

export const SuccessScreen: React.FC<Props> = ({ onGoBack, message }) => (
  <div className="flex  flex-col items-center justify-center">
    <svg xmlns="http://www.w3.org/2000/svg" width="140" height="184" fill="none">
      <path
        fill="#DCFCE7"
        d="M124.558 8.558c-5.811 26.84-20.716 89.51-35.838 102.615a8.748 8.748 0 0 1-3.365 2.067c-53.153 13.472-70.184-20.89-70.184-20.89s14.244-7.222 14.605-21.338l28.726 9.273 25.042-58.626 3.684-3.576.021-.021 37.309-9.504Z"
      />
      <path
        fill="#E6E6E6"
        d="M.192 171.25.616 12.115c.012-3.22 1.252-6.305 3.447-8.577C6.258 1.268 9.23-.005 12.326 0l115.523.333c3.096.013 6.06 1.302 8.244 3.586 2.183 2.284 3.406 5.376 3.401 8.596l-.424 159.136c-.012 3.221-1.252 6.305-3.447 8.576-2.196 2.271-5.167 3.544-8.263 3.539l-115.523-.334c-3.096-.012-6.06-1.302-8.244-3.586-2.183-2.283-3.406-5.375-3.401-8.596Z"
      />
      <path
        fill="#fff"
        d="m9.181 123.21.273-102.306c.011-3.017 1.172-5.905 3.228-8.033 2.056-2.127 4.839-3.318 7.739-3.313l99.282.286c2.899.012 5.676 1.22 7.721 3.358 2.044 2.14 3.19 5.034 3.185 8.051l-.377 141.609c-.011 3.016-1.173 5.905-3.229 8.032s-4.839 3.319-7.738 3.314l-61.504-.178c-12.915-.052-25.283-5.432-34.39-14.959-9.108-9.528-14.211-22.424-14.19-35.861Z"
      />
      <path
        fill="#22C55E"
        d="M108.327 54.497H30.912a4.555 4.555 0 0 1-3.284-1.416 4.933 4.933 0 0 1-1.361-3.417c0-1.281.49-2.51 1.36-3.416a4.555 4.555 0 0 1 3.285-1.416h77.415c1.231 0 2.413.51 3.284 1.416a4.933 4.933 0 0 1 1.36 3.416c0 1.282-.489 2.511-1.36 3.417a4.556 4.556 0 0 1-3.284 1.416ZM47.943 32.215H30.912a4.555 4.555 0 0 1-3.284-1.415 4.933 4.933 0 0 1-1.361-3.417c0-1.282.49-2.511 1.36-3.417a4.555 4.555 0 0 1 3.285-1.416h17.031c1.232 0 2.413.51 3.285 1.416a4.934 4.934 0 0 1 1.36 3.417c0 1.281-.49 2.51-1.36 3.417a4.556 4.556 0 0 1-3.285 1.415ZM108.327 76.779H30.912a4.555 4.555 0 0 1-3.284-1.416 4.933 4.933 0 0 1-1.361-3.416c0-1.282.49-2.511 1.36-3.417a4.555 4.555 0 0 1 3.285-1.416h77.415c1.231 0 2.413.51 3.284 1.416a4.933 4.933 0 0 1 1.36 3.416c0 1.282-.489 2.511-1.36 3.417a4.556 4.556 0 0 1-3.284 1.416ZM108.327 99.06H30.912a4.555 4.555 0 0 1-3.284-1.415 4.933 4.933 0 0 1-1.361-3.417c0-1.281.49-2.51 1.36-3.417a4.555 4.555 0 0 1 3.285-1.415h77.415c1.231 0 2.413.51 3.284 1.415a4.933 4.933 0 0 1 1.36 3.417c0 1.282-.489 2.511-1.36 3.417a4.556 4.556 0 0 1-3.284 1.416ZM102.063 157.773c10.207 0 18.482-8.609 18.482-19.228 0-10.619-8.275-19.227-18.482-19.227-10.208 0-18.482 8.608-18.482 19.227s8.274 19.228 18.482 19.228Z"
      />
      <path
        fill="#fff"
        d="M99.541 148.552c-.319 0-.634-.077-.919-.226a2.069 2.069 0 0 1-.725-.63l-5.042-6.994a2.17 2.17 0 0 1-.348-.752 2.225 2.225 0 0 1 .222-1.622c.137-.242.32-.453.535-.622.217-.169.463-.291.724-.361a1.971 1.971 0 0 1 1.559.233c.232.143.435.333.597.558l3.299 4.575 8.472-13.221a2.12 2.12 0 0 1 .566-.593 1.98 1.98 0 0 1 1.546-.324c.265.054.518.162.743.318.225.157.418.357.569.591a2.202 2.202 0 0 1 .306 1.609 2.182 2.182 0 0 1-.309.772L101.252 147.6a2.1 2.1 0 0 1-.715.685c-.288.165-.61.257-.938.266l-.058.001Z"
      />
    </svg>
    <h1 className="p-4 text-3xl text-center">Successfully Updated</h1>
    {message && <p className="p-4 text-gray-500 text-center text-lg">{message}</p>}
    <Button onClick={onGoBack}>Go back</Button>
  </div>
);
