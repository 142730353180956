import { getErrorMessage } from "@/utils";
import { summon } from "@/utils/summon";
import { z } from "zod";

// const token = JSON.parse(localStorage.getItem("rc-agent-creation") as string);

export const UserDetailsSchema = z.object({
	bvn: z
		.string({ required_error: "BVN is required" })
		.regex(/^[0-9]+$/, { message: "BVN must be a number" })
		.min(11, { message: "BVN must be 11 characters long" })
		.max(11, { message: "BVN must be 11 characters long" }),
	nin: z.string().optional(),
});

export type UserDetailsData = z.infer<typeof UserDetailsSchema>;

export type UserDetailReturnedValue = {
	status: boolean;
	info: {
		liveCapture: string;
		liveFaceCapture: boolean;
	};
};

export const createOrReactivateUser = async (
	data: UserDetailsData,
	token: string,
): Promise<UserDetailReturnedValue | undefined> => {
	try {
		const response = await summon.post<UserDetailReturnedValue>(
			`${import.meta.env.VITE_APP_PILOT_URL}/api/auth/customer-wallet/initiate`,
			data,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);

		return response;
	} catch (error) {
		throw new Error(getErrorMessage(error));
	}
};
