import {
	Action,
	RESET,
	SET_CAPTURED_IMAGE,
	SET_DONE,
	SET_FACE_STRUCTURE,
	SET_MESSAGE,
	SET_PERCENTAGE,
	SET_SERVER_ERROR,
	SET_SUBMITTING,
	SET_SUCCESS,
	State,
} from "./actionCreators";

export const initialState: State = {
	message: "Loading models...",
	percentage: 0,
	done: false,
	capturedImage: null,
	faceStructure: { smiling: false },
	serverError: "",
	success: "",
	isSubmitting: false,
};

function livenessCheckReducer(state: State, action: Action): State {
	switch (action.type) {
		case SET_MESSAGE:
			return { ...state, message: action.payload };
		case SET_PERCENTAGE:
			return { ...state, percentage: action.payload };
		case SET_DONE:
			return { ...state, done: action.payload };
		case SET_CAPTURED_IMAGE:
			return { ...state, capturedImage: action.payload };
		case SET_FACE_STRUCTURE:
			return { ...state, faceStructure: action.payload };
		case SET_SERVER_ERROR:
			return { ...state, serverError: action.payload, percentage: 0 };
		case SET_SUCCESS:
			return { ...state, success: action.payload };
		case SET_SUBMITTING:
			return { ...state, isSubmitting: action.payload };
		case RESET:
			return initialState;
		default:
			return state;
	}
}

export default livenessCheckReducer;
