import { getErrorMessage } from "@/utils";

type ReturnedData = {
  status: boolean;
  message: string;
};

// const token = JSON.parse(localStorage.getItem("rc-agent-creation") as string);

export const submitVerification = async (
  imageSrc: string,
  video: string,
  liveCapture: string,
  token: string,
): Promise<ReturnedData | undefined> => {
  const formData = new FormData();
  formData.append("faceImage", imageSrc);
  formData.append("video", video);

  try {
    const response = await fetch(`${import.meta.env.VITE_APP_PILOT_URL}${liveCapture}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    if (!response.ok) {
      const error = await response.json().catch(() => ({}));
      const errorMessage = error.error || error.message || response.statusText;
      throw new Error(errorMessage);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};
