import {
	CreateWalletIcon,
	LeftArrowIcon,
	LinkWalletIcon,
	ReactivateWalletIcon,
} from "@/components/svg";
import { createFileRoute, useNavigate } from "@tanstack/react-router";

export const Route = createFileRoute("/_main/welcome")({
	component: WelcomePage,
});

export default function WelcomePage() {
	const navigate = useNavigate();

	const handleCreateWallet = () => {
		navigate({ to: "/user-details" });
	};

	const handleLinkWallet = () => {
		navigate({ to: "/get-wallets" });
	};

	return (
		<div className="grow flex justify-center items-center flex-col">
			<div className="text-center">
				<h1 className="text-4xl font-pPRightGothic text-[#374151]">Welcome</h1>
				<p className="text-[#374151] text-lg pt-2">What do you want to do right now?</p>
			</div>
			<div className="pt-6 flex md:flex-row flex-col justify-center items-center gap-6">
				<div
					className="shadow bg-white rounded-lg p-4 flex gap-4 items-center cursor-pointer hover:shadow-md w-full max-w-[350]"
					onClick={handleCreateWallet}
				>
					<CreateWalletIcon />
					<div>
						<h3 className="font-pPRightGothic text-lg text-gray-800">Create Wallet</h3>
						<p className="text-xs pt-1 text-[#374151]">Create wallet account for new users</p>
					</div>
					<LeftArrowIcon />
				</div>
				<div
					className="shadow bg-white rounded-lg p-4 flex gap-4 items-center cursor-pointer hover:shadow-md w-full max-w-[350]"
					onClick={handleLinkWallet}
				>
					<LinkWalletIcon />
					<div>
						<h3 className="font-pPRightGothic text-lg text-gray-800">Link Wallet</h3>
						<p className="text-xs pt-1 text-[#374151]">Link existing wallet to HMIS</p>
					</div>
					<LeftArrowIcon />
				</div>
				<div
					className="shadow bg-white rounded-lg p-4 flex gap-4 items-center cursor-pointer hover:shadow-md w-full max-w-[350]"
					onClick={handleCreateWallet}
				>
					<ReactivateWalletIcon />
					<div>
						<h3 className="font-pPRightGothic text-lg text-gray-800">Reactivate Wallet</h3>
						<p className="text-xs pt-1 text-[#374151]">Reactivate existing wallet account</p>
					</div>
					<LeftArrowIcon />
				</div>
			</div>
		</div>
	);
}
