// import { useRef, useEffect, FC } from "react";
// import * as faceapi from "face-api.js";
// import { getErrorMessage } from "@/utils";

// interface IFace {
// 	smiling: boolean;
// }

// interface IWebcam {
// 	setMessage: (val: string) => void;
// 	setPercentage: (val: number) => void;
// 	faceStructure: IFace;
// 	setFaceStructure: React.Dispatch<React.SetStateAction<IFace>>;
// 	done: boolean;
// 	facingMode: "user" | "environment";
// }

// const WebcamWithFaceDetection: FC<IWebcam> = ({
// 	setMessage,
// 	setPercentage,
// 	setFaceStructure,
// 	done,
// 	facingMode,
// }) => {
// 	const webcamRef = useRef<HTMLVideoElement>(null);
// 	const SMILE_THRESHOLD = 0.7;
// 	const smileCount = useRef(0);
// 	const MAX_SMILES = 3;

// 	useEffect(() => {
// 		let cleanupFaceDetect: (() => void) | undefined;
// 		let stream: MediaStream | null = null;

// 		const startVideo = async () => {
// 			try {
// 				stream = await navigator.mediaDevices.getUserMedia({
// 					video: { facingMode },
// 				});
// 				if (webcamRef.current) {
// 					webcamRef.current.srcObject = stream;
// 				}
// 			} catch (err) {
// 				throw new Error(getErrorMessage(err));
// 			}
// 		};

// 		const loadModels = async () => {
// 			try {
// 				await Promise.all([
// 					faceapi.nets.tinyFaceDetector.loadFromUri("/models"),
// 					faceapi.nets.faceLandmark68Net.loadFromUri("/models"),
// 					faceapi.nets.faceRecognitionNet.loadFromUri("/models"),
// 					faceapi.nets.faceExpressionNet.loadFromUri("/models"),
// 					faceapi.nets.ssdMobilenetv1.loadFromUri("/models"),
// 					faceapi.nets.faceLandmark68TinyNet.loadFromUri("/models"),
// 				]);
// 			} catch (error) {
// 				throw new Error(getErrorMessage(error));
// 			}
// 		};

// 		const faceMyDetect = () => {
// 			if (done) return;

// 			const intervalId = setInterval(async () => {
// 				if (webcamRef.current) {
// 					const detections = await faceapi
// 						.detectSingleFace(
// 							webcamRef.current,
// 							new faceapi.TinyFaceDetectorOptions({
// 								inputSize: 128,
// 								scoreThreshold: 0.2,
// 							}),
// 						)
// 						.withFaceLandmarks()
// 						.withFaceExpressions();

// 					if (detections && detections.expressions) {
// 						const smileProb = detections.expressions.happy;
// 						if (smileProb > SMILE_THRESHOLD) {
// 							smileCount.current += 1;
// 							const percentage = (smileCount.current / MAX_SMILES) * 100;
// 							setPercentage(percentage);
// 							setMessage(`Smile detected! ${MAX_SMILES - smileCount.current} more to go.`);
// 							if (smileCount.current >= MAX_SMILES) {
// 								setFaceStructure({ smiling: true });
// 								setMessage("Liveness verification complete!");
// 							}
// 						} else {
// 							setMessage("Please smile at the camera");
// 						}
// 					} else {
// 						setMessage("No face detected, please face the camera");
// 					}
// 				}
// 			}, 1000);

// 			return () => clearInterval(intervalId);
// 		};

// 		const initializeCamera = async () => {
// 			await startVideo();
// 			await loadModels();
// 			cleanupFaceDetect = faceMyDetect();
// 		};

// 		initializeCamera();

// 		return () => {
// 			if (stream) {
// 				stream.getTracks().forEach((track) => track.stop());
// 			}
// 			if (cleanupFaceDetect) cleanupFaceDetect();
// 		};
// 	}, [facingMode, done, setMessage, setPercentage, setFaceStructure]);

// 	return (
// 		<video className="size-full object-cover" crossOrigin="anonymous" ref={webcamRef} autoPlay />
// 	);
// };

// export default WebcamWithFaceDetection;

import { useRef, useEffect, FC } from "react";
import * as faceapi from "face-api.js";
import { getErrorMessage } from "@/utils";
import { useModels } from "@/faceModelContext";

interface IFace {
	smiling: boolean;
}

interface IWebcam {
	setMessage: (val: string) => void;
	setPercentage: (val: number) => void;
	faceStructure: IFace;
	setFaceStructure: React.Dispatch<React.SetStateAction<IFace>>;
	done: boolean;
	facingMode: "user" | "environment";
}

const WebcamWithFaceDetection: FC<IWebcam> = ({
	setMessage,
	setPercentage,
	setFaceStructure,
	done,
	facingMode,
}) => {
	const webcamRef = useRef<HTMLVideoElement>(null);
	const { modelsLoaded } = useModels();
	const SMILE_THRESHOLD = 0.7;
	const smileCount = useRef(0);
	const MAX_SMILES = 3;

	useEffect(() => {
		let cleanupFaceDetect: (() => void) | undefined;
		let stream: MediaStream | null = null;

		const startVideo = async () => {
			try {
				stream = await navigator.mediaDevices.getUserMedia({
					video: { facingMode },
				});
				if (webcamRef.current) {
					webcamRef.current.srcObject = stream;
				}
			} catch (err) {
				throw new Error(getErrorMessage(err));
			}
		};

		const faceMyDetect = () => {
			if (done) return;

			const intervalId = setInterval(async () => {
				if (webcamRef.current && modelsLoaded) {
					const detections = await faceapi
						.detectSingleFace(
							webcamRef.current,
							new faceapi.TinyFaceDetectorOptions({
								inputSize: 128,
								scoreThreshold: 0.2,
							}),
						)
						.withFaceLandmarks()
						.withFaceExpressions();

					if (detections && detections.expressions) {
						const smileProb = detections.expressions.happy;
						if (smileProb > SMILE_THRESHOLD) {
							smileCount.current += 1;
							const percentage = (smileCount.current / MAX_SMILES) * 100;
							setPercentage(percentage);
							setMessage(`Smile detected! ${MAX_SMILES - smileCount.current} more to go.`);
							if (smileCount.current >= MAX_SMILES) {
								setFaceStructure({ smiling: true });
								setMessage("Liveness verification complete!");
							}
						} else {
							setMessage("Please smile at the camera");
						}
					} else {
						setMessage("No face detected, please face the camera");
					}
				}
			}, 1000);

			return () => clearInterval(intervalId);
		};

		const initializeCamera = async () => {
			await startVideo();
			if (modelsLoaded) {
				cleanupFaceDetect = faceMyDetect();
			}
		};

		initializeCamera();

		return () => {
			if (stream) {
				stream.getTracks().forEach((track) => track.stop());
			}
			if (cleanupFaceDetect) cleanupFaceDetect();
		};
	}, [facingMode, done, setMessage, setPercentage, setFaceStructure, modelsLoaded]);

	return (
		<video className="size-full object-cover" crossOrigin="anonymous" ref={webcamRef} autoPlay />
	);
};

export default WebcamWithFaceDetection;
