import { getHospitals } from "@/apis/getHospitals";
import { LinkWallet, PatientDetailsSchema, PetientDetailsData } from "@/apis/linkWallet";
import { Button, Input } from "@/components";
import CustomSelect from "@/components/select";
import { Skeleton } from "@/components/skeleton";
import { ErrorScreen } from "@/features/livenessCheck/error";
import { SuccessScreen } from "@/features/livenessCheck/success";
import { useFormUtils } from "@/hooks/useFormUtils";
import { Errors } from "@/utils/types";
import { createFileRoute, useLocation, useNavigate, useRouteContext } from "@tanstack/react-router";
import { FormEvent, SetStateAction, useEffect, useState } from "react";

export const Route = createFileRoute("/_main/link-wallet")({
	component: LinkWalletPage,
});

type State = {
	formData: PetientDetailsData;
	errors: Errors | null;
	isSubmitting: boolean;
	serverError: string | null;
};

export default function LinkWalletPage() {
	const context = useRouteContext({ strict: false });
	const [loading, setLoading] = useState(true);
	const location = useLocation();
	const navigate = useNavigate();
	const [options, setOptions] = useState([]);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState("");
	const [selectedValue, setSelectedValue] = useState<string>("");
	// @ts-expect-error - TSCONVERSION
	const walletId = location.state.walletId;

	const initialState: State = {
		formData: {
			patientId: "",
			phoneNumber: "",
			terminalId: selectedValue,
			walletId,
		},
		errors: null,
		isSubmitting: false,
		serverError: null,
	};

	const { handleSubmit, handleBlur, handleChange, state } = useFormUtils(
		PatientDetailsSchema,
		initialState,
	);

	useEffect(() => {
		const fetchHospitals = async () => {
			try {
				// @ts-expect-error auth
				const res = await getHospitals(context.auth.user);
				// @ts-expect-error - TSCONVERSION
				setOptions(res?.data);
			} catch (error) {
				console.error("Error fetching hospitals:", error);
				setError("Error fetching hospitals");
			} finally {
				setLoading(false);
			}
		};
		fetchHospitals();
		// @ts-expect-error - TSCONVERSION
	}, [context.auth.user]);

	const handleSelectChange = (value: SetStateAction<string>) => {
		setSelectedValue(value);
	};

	const submit = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		await handleSubmit(event, async (formData) => {
			// @ts-expect-error auth
			await LinkWallet({ ...formData, terminalId: selectedValue }, context.auth.user).then(() =>
				setSuccess(true),
			);
			// console.log({ ...formData, selectedValue });
		});
	};

	if (success) {
		return <SuccessScreen onGoBack={() => navigate({ to: "/" })} />;
	}

	if (error) {
		return <ErrorScreen error={error} onRetry={() => window.location.reload()} />;
	}

	if (state.serverError) {
		return <ErrorScreen error={state.serverError} onRetry={() => window.location.reload()} />;
	}

	return (
		<form onSubmit={submit} className="py-8 w-[300px]">
			{loading ? (
				<div className="flex items-center space-x-4 mt-8">
					<div className="space-y-2">
						<Skeleton className="h-4 w-[300px]" />
						<Skeleton className="h-4 w-[300px]" />
					</div>
				</div>
			) : (
				<>
					<Input
						label="Wallet ID"
						name="walletId"
						type="text"
						placeholder="Please enter your Wallet ID"
						autoComplete=""
						disabled
						defaultValue={walletId}
						onChange={handleChange}
						onBlur={handleBlur}
						error={(state.errors as Errors)?.walletId}
					/>
					<Input
						label="Phone Number"
						name="phoneNumber"
						type="text"
						placeholder="Please enter your phone number"
						onChange={handleChange}
						onBlur={handleBlur}
						error={(state.errors as Errors)?.phoneNumber}
					/>

					<CustomSelect
						label="Select Hospital"
						options={options.map((option) => ({
							// @ts-expect-error - TSCONVERSION
							label: option.hospitalName,
							// @ts-expect-error - TSCONVERSION
							value: option.terminalId,
						}))}
						placeholder="Choose a hospital"
						onChange={(value) => {
							handleSelectChange(value);
							// @ts-expect-error - TSCONVERSION
							handleChange({ target: { name: "terminalId", value } });
						}}
						className="max-w-xs"
						error={(state.errors as Errors)?.terminalId}
					/>

					<Input
						label="Patient ID"
						name="patientId"
						type="text"
						placeholder="Please enter your patient ID"
						onChange={handleChange}
						onBlur={handleBlur}
						error={(state.errors as Errors)?.patientId}
					/>
				</>
			)}

			<Button isLoading={state.isSubmitting} className="mt-8" type="submit" fullWidth>
				Link Wallet
			</Button>
		</form>
	);
}
