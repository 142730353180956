// import { createContext, ReactNode, useContext, useEffect, useState } from "react";
// import { loadAndCacheModels } from "./utils/faceApiLoader";

// type ModelContextProps = {
// 	modelsLoaded: boolean;
// };

// export const FaceModelContext = createContext<ModelContextProps>({ modelsLoaded: false });

// export const FaceModelProvider = ({ children }: { children: ReactNode }) => {
// 	const [modelsLoaded, setModelsLoaded] = useState(false);

// 	useEffect(() => {
// 		const loadModels = async () => {
// 			await loadAndCacheModels();
// 			setModelsLoaded(true); // Set when models are loaded
// 		};

// 		loadModels();
// 	}, []);

// 	return <FaceModelContext.Provider value={{ modelsLoaded }}>{children}</FaceModelContext.Provider>;
// };

// // eslint-disable-next-line react-refresh/only-export-components
// export function useFaceModels() {
// 	const context = useContext(FaceModelContext);
// 	if (!context) {
// 		throw new Error("useAuth must be used within an AuthProvider");
// 	}
// 	return context;
// }

import { createContext, useState, useEffect, ReactNode, useContext } from "react";
import * as faceapi from "face-api.js";

interface ModelsContextType {
	modelsLoaded: boolean;
	loadModels: () => Promise<void>;
}

const ModelsContext = createContext<ModelsContextType | undefined>(undefined);

const ModelsProvider = ({ children }: { children: ReactNode }) => {
	const [modelsLoaded, setModelsLoaded] = useState(false);

	const loadModels = async () => {
		try {
			await Promise.all([
				faceapi.nets.tinyFaceDetector.loadFromUri("/models"),
				faceapi.nets.faceLandmark68Net.loadFromUri("/models"),
				faceapi.nets.faceRecognitionNet.loadFromUri("/models"),
				faceapi.nets.faceExpressionNet.loadFromUri("/models"),
				faceapi.nets.ssdMobilenetv1.loadFromUri("/models"),
				faceapi.nets.faceLandmark68TinyNet.loadFromUri("/models"),
			]);
			setModelsLoaded(true);
		} catch (error) {
			console.error("Failed to load models:", error);
		}
	};

	useEffect(() => {
		loadModels();
	}, []);

	return (
		<ModelsContext.Provider value={{ modelsLoaded, loadModels }}>{children}</ModelsContext.Provider>
	);
};

// eslint-disable-next-line react-refresh/only-export-components
export function useModels() {
	const context = useContext(ModelsContext);
	if (!context) {
		throw new Error("useAuth must be used within an AuthProvider");
	}
	return context;
}

export { ModelsProvider, ModelsContext };
