import { getErrorMessage } from "@/utils";
import { summon } from "@/utils/summon";

// const token = JSON.parse(localStorage.getItem("rc-agent-creation") as string);

type ReturnedValue = {
	status: boolean;
	message: string;
	data: {
		account_numbers: string[];
	};
};

export const getWallets = async (token: string): Promise<ReturnedValue | undefined> => {
	try {
		const response = await summon.get<ReturnedValue>(
			`${import.meta.env.VITE_APP_PILOT_URL}/api/auth/customer-wallet/agent/wallets`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);
		return response;
	} catch (error) {
		throw new Error(getErrorMessage(error));
	}
};
