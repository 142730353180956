import { validateWithZod } from "@/utils";
import { Errors, FormData } from "@/utils/types";
import { z } from "zod";
import { Action, setErrors, setFormData, setServerError, setSubmitting } from "./actionCreators";

export const handleFormSubmit = async <T extends FormData>(
  event: React.FormEvent<HTMLFormElement>,
  schema: z.ZodType<T>,
  formData: T,
  onSubmit: (formData: T) => Promise<void>,
  dispatch: React.Dispatch<Action<T>>,
) => {
  event.preventDefault();
  const errors = validateWithZod(schema, formData);
  if (errors) {
    dispatch(setErrors(errors));
  } else {
    dispatch(setErrors(null));
    dispatch(setServerError(null));
    dispatch(setSubmitting(true));
    try {
      await onSubmit(formData);
    } catch (error: unknown) {
      if (error instanceof Error) {
        dispatch(setServerError(error.message));
      } else {
        dispatch(setServerError("An unknown error occurred."));
      }
    } finally {
      dispatch(setSubmitting(false));
    }
  }
};

export const handleBlur = <T extends FormData>(
  event: React.FocusEvent<HTMLInputElement>,
  schema: z.ZodSchema<T>,
  formData: T,
  errors: Errors | null,
  dispatch: React.Dispatch<Action<T>>,
): void => {
  const { name } = event.target;
  const validationErrors = validateWithZod(schema, formData);
  if (validationErrors) {
    dispatch(setErrors({ ...(errors ?? {}), [name]: validationErrors[name] }));
  } else {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch(setErrors({ ...((errors as Record<string, any>) ?? {}), [name]: undefined }));
  }
};

export const handleChange = <T extends FormData>(
  event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  dispatch: React.Dispatch<Action<T>>,
  formData: T,
): void => {
  const { name, value } = event.target;
  dispatch(setFormData({ ...formData, [name]: value }));
  dispatch(setServerError(null));
  dispatch(setErrors(null));
};
