import React from "react";
import { Button } from "@/components";

type Props = {
	error: string;
	onRetry: () => void;
};

export const ErrorScreen: React.FC<Props> = ({ error, onRetry }) => (
	<div className="flex h-screen flex-col items-center justify-center">
		<svg xmlns="http://www.w3.org/2000/svg" width="183" height="139" fill="none">
			<path
				fill="#FFE1E1"
				d="m153.699 138.435-116.602.04c-5.858.002-11.106-3.025-14.036-8.097-1.466-2.536-2.199-5.319-2.2-8.102 0-2.782.73-5.565 2.194-8.102L81.32 8.107C84.247 3.032 89.492.002 95.35 0c5.858-.002 11.105 3.025 14.036 8.097l58.345 106.04a16.133 16.133 0 0 1 2.193 8.09c0 2.781-.731 5.564-2.194 8.101-2.928 5.074-8.173 8.105-14.031 8.107Zm-131.372-16.16c0 2.532 12.867-2.926 14.2-.618 2.666 4.614-4.76 15.357.57 15.356 0 0 126.703-2.8 129.366-7.416 1.331-2.308 1.997-4.84 1.996-7.372a14.706 14.706 0 0 0-2.002-7.37L108.112 8.815c-2.659-4.6-7.432-7.354-12.761-7.352-5.327.001-73.024 118.278-73.024 120.812Z"
			/>
			<path
				fill="#B20000"
				d="M129.375 115.043c29.481 0 53.381-23.9 53.381-53.381 0-29.482-23.9-53.381-53.381-53.381-29.482 0-53.382 23.9-53.382 53.38 0 29.483 23.9 53.382 53.382 53.382Z"
			/>
			<path
				fill="#fff"
				d="M129.384 94.385a4.901 4.901 0 1 0 0-9.802 4.901 4.901 0 0 0 0 9.802ZM129.363 28.939c-.45 2.991.357 3.617.361 16.515.005 12.898 2.681 30.19-.345 30.191-3.025.001-4.132-35.687-14.637-38.845-12.351-3.714 16.079-17.546 14.621-7.861Z"
			/>
			<path
				fill="#FFA5A5"
				d="m57.73 88.916-4.823 10.19c5.83-4.18 14.937-7.785 22.184-9.715-6.753-3.262-15.015-8.52-19.951-13.727l2.774 10.767C25.392 79.807 2.038 54.86 2.028 26.28l-1.175-.404c.01 29.852 23.042 56.278 56.876 63.041Z"
			/>
		</svg>
		<h1 className="p-4 text-3xl">{error}</h1>
		<Button onClick={onRetry}>Try again</Button>
	</div>
);
