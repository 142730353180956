import { createFileRoute, useRouteContext } from "@tanstack/react-router";

import CreateOrReactivateUser from "@/features/createOrReactivateUser";

export const Route = createFileRoute("/_main/user-details")({
	component: UserDetailsPage,
});

export default function UserDetailsPage() {
	const context = useRouteContext({ strict: false });
	// @ts-expect-error auth
	return <CreateOrReactivateUser token={context.auth.user} />;
}
