import { getWallets } from "@/apis/getWallets";
import { Button } from "@/components";
import { Skeleton } from "@/components/skeleton";
import { Toaster } from "@/components/toaster";
import { createFileRoute, useNavigate, useRouteContext } from "@tanstack/react-router";
import { FormEvent, useEffect, useState } from "react";

export const Route = createFileRoute("/_main/get-wallets")({
	component: GetWalletsPage,
});

export default function GetWalletsPage() {
	const context = useRouteContext({ strict: false });
	const [accounts, setAccounts] = useState<string[]>([]);
	const [loading, setLoading] = useState(true);
	const [selectedAccount, setSelectedAccount] = useState<string | null>(null);
	const [error, setError] = useState<string | null>(null);
	const navigate = useNavigate();

	const handleSelect = (account: string) => {
		setSelectedAccount(account);
	};

	const submit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (!selectedAccount) {
			setError("Please select an account");
			return;
		}
		navigate({
			to: "/link-wallet",
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			state(prev: any) {
				return { ...prev, walletId: selectedAccount };
			},
		});
	};

	useEffect(() => {
		// @ts-expect-error auth
		getWallets(context.auth.user)
			.then((res) => {
				setAccounts(res?.data.account_numbers as string[]);
			})
			.then(() => setLoading(false));
		// @ts-expect-error auth
	}, [context.auth.user]);

	return (
		<>
			{error && <Toaster key={Math.random()} type={"error"} message={error} />}
			<form onSubmit={submit}>
				<fieldset className="mt-8">
					<legend className="text-sm font-semibold leading-6 text-gray-900">Select Account</legend>
					<p className="mt-1 text-sm leading-6 text-gray-600">
						Select Account number you want to link to patient
					</p>
					{loading ? (
						<div className="flex items-center space-x-4 mt-8">
							<div className="space-y-2">
								<Skeleton className="h-4 w-[300px]" />
								<Skeleton className="h-4 w-[300px]" />
							</div>
						</div>
					) : (
						<div className="mt-6 space-y-2">
							{accounts &&
								accounts.map((account) => (
									<div
										key={account}
										className={`flex items-center gap-x-3 border p-4 rounded ${
											selectedAccount === account ? "border-pw-primary" : "border-gray-300"
										}`}
										onClick={() => handleSelect(account)}
									>
										<input
											id={`account-${account}`}
											name="selected-account"
											type="radio"
											checked={selectedAccount === account}
											onChange={() => handleSelect(account)}
											className="h-4 w-4 border-gray-300 text-pw-primary focus:ring-pw-primary"
										/>
										<label
											htmlFor={`account-${account}`}
											className="block text-sm font-medium leading-6 text-gray-900"
										>
											{account}
										</label>
									</div>
								))}
						</div>
					)}
				</fieldset>
				<Button className="mt-8" type="submit" fullWidth>
					Continue to Link Wallet
				</Button>
			</form>
		</>
	);
}
