import { FormEvent, useState } from "react";
import { LoginData, LoginSchema } from "@/apis/login";
import { Button, Input } from "@/components";
import { useFormUtils } from "@/hooks/useFormUtils";
import { Errors } from "@/utils/types";
import { Toaster } from "@/components/toaster";
import { EyeCloseIcon, EyeIcon } from "@/components/icons";
import { useNavigate, useRouter } from "@tanstack/react-router";
import { useAuth } from "@/authContext";
import { delay, getErrorMessage } from "@/utils";

type State = {
	formData: LoginData;
	errors: Errors | null;
	isSubmitting: boolean;
	serverError: string | null;
};

const initialState: State = {
	formData: {
		agent_id: "",
		password: "",
	},
	errors: null,
	isSubmitting: false,
	serverError: null,
};

export default function Login() {
	const { handleSubmit, handleChange, handleBlur, state } = useFormUtils(LoginSchema, initialState);
	const [hidePassword, setHidePassword] = useState(true);
	const router = useRouter();
	const navigate = useNavigate();
	const auth = useAuth();

	const submit = async (event: FormEvent<HTMLFormElement>) => {
		try {
			await handleSubmit(event, async (formData) => {
				await auth.login(formData.agent_id, formData.password);
				await router.invalidate();
				await delay(1);
				await navigate({ to: "/welcome" });
			});
		} catch (error) {
			throw new Error(getErrorMessage(error));
		}
	};
	return (
		<div className="flex flex-col justify-center items-center h-screen w-screen">
			<img src="/logo.svg" alt="logo" />
			{state.serverError && (
				<Toaster key={Math.random()} type={"error"} message={state.serverError} />
			)}
			<form onSubmit={submit} className="py-8 w-[300px]">
				<Input
					label="Agent ID"
					name="agent_id"
					type="text"
					placeholder="Please enter your agent ID"
					autoComplete=""
					onChange={handleChange}
					onBlur={handleBlur}
					error={(state.errors as Errors)?.agent_id}
				/>
				<Input
					label="Password"
					name="password"
					type={hidePassword ? "password" : "text"}
					autoComplete="current-password"
					placeholder="Please enter your password"
					onChange={handleChange}
					onBlur={handleBlur}
					error={(state.errors as Errors)?.password}
					suffix={
						<div onClick={() => setHidePassword(!hidePassword)} className="cursor-pointer">
							{hidePassword ? <EyeCloseIcon /> : <EyeIcon />}
						</div>
					}
				/>
				<Button type="submit" isLoading={state.isSubmitting} fullWidth>
					Login
				</Button>
			</form>
			{/*<Button asChild variant="ghost" className="text-neutral-500 w-[300px]">
				<Link to="/user-details">Don't have Agent ID</Link>
			</Button>*/}
		</div>
	);
}
