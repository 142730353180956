import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "@tanstack/react-router";
import { useLivenessCheck } from "@/hooks/useLivenessCheck";
import WebcamWithFaceDetection from "./webCamWithFaceDetection";
import {
	RESET,
	SET_DONE,
	SET_FACE_STRUCTURE,
	SET_MESSAGE,
	SET_PERCENTAGE,
} from "@/states/livenessCheckState/actionCreators";
import { useVideoCapture } from "@/hooks/useVideoCapture";
import { ErrorScreen } from "./error";
import { SuccessScreen } from "./success";
import Verification from "./verification";
import { Button } from "@/components";

const FACING_MODE_USER = "user" as const;
const FACING_MODE_ENVIRONMENT = "environment" as const;

type FacingMode = typeof FACING_MODE_USER | typeof FACING_MODE_ENVIRONMENT;

export default function LivenessCheck({ token }: { token: string }) {
	const [facingMode, setFacingMode] = useState<FacingMode>(FACING_MODE_USER);
	const location = useLocation();
	const navigate = useNavigate();

	// @ts-expect-error - TSCONVERSION
	const userData = location.state.userData;
	const { video, isVideoReady, startVideoCapture } = useVideoCapture();
	const { state, dispatch, handleCaptureAndSubmit, webcamRef } = useLivenessCheck(
		userData,
		video,
		token,
	);

	const videoConstraints = useMemo(
		() => ({
			facingMode: facingMode,
		}),
		[facingMode],
	);

	useEffect(() => {
		if (state.percentage === 100) {
			dispatch({ type: SET_MESSAGE, payload: "Great! Liveness Verification is Complete" });
			dispatch({ type: SET_DONE, payload: true });
		}
		startVideoCapture();
	}, [dispatch, startVideoCapture, state.percentage]);

	const handleSwitchCamera = useCallback(() => {
		setFacingMode((prevState) =>
			prevState === FACING_MODE_USER ? FACING_MODE_ENVIRONMENT : FACING_MODE_USER,
		);
	}, []);

	const WebcamWithFaceDetectionMemo = useMemo(
		() => (
			<WebcamWithFaceDetection
				setMessage={(message) => dispatch({ type: SET_MESSAGE, payload: message })}
				setPercentage={(percentage) => dispatch({ type: SET_PERCENTAGE, payload: percentage })}
				faceStructure={state.faceStructure}
				setFaceStructure={(faceStructure) =>
					dispatch({ type: SET_FACE_STRUCTURE, payload: faceStructure as never })
				}
				facingMode={facingMode}
				done={state.done}
			/>
		),
		[state.faceStructure, state.done, facingMode, dispatch],
	);

	if (state.serverError)
		return <ErrorScreen error={state.serverError} onRetry={() => dispatch({ type: RESET })} />;
	if (state.success) {
		console.log({ success: state.success });
		return (
			<>
				<SuccessScreen message={state.success} onGoBack={() => navigate({ to: "/" })} />
				<Button
					className="mt-4"
					variant="secondary"
					onClick={() => navigate({ to: "/get-wallets" })}
				>
					Link Wallet
				</Button>
			</>
		);
	}

	return (
		<Verification
			handleCaptureAndSubmit={handleCaptureAndSubmit}
			// @ts-expect-error - TSCONVERSION
			hanleSwitchCamera={handleSwitchCamera}
			isVideoReady={isVideoReady}
			state={state}
			videoConstraints={videoConstraints}
			webcamRef={webcamRef}
			WebcamWithFaceDetectionMemo={WebcamWithFaceDetectionMemo}
		/>
	);
}
