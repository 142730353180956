import React from "react";

type IconProps = React.SVGAttributes<SVGElement> & {
	size?: number;
	color?: string;
};

export const SpinnerIcon: React.FC<IconProps> = ({
	size = 24,
	color = "currentColor",
	className,
	...props
}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			stroke={color}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
			className={`animate-spin ${className || ""}`}
			{...props}
		>
			<circle cx="12" cy="12" r="10" opacity="0.25" />
			<path d="M12 2a10 10 0 0 1 10 10" />
		</svg>
	);
};

export const DangerIcon: React.FC<IconProps> = ({ size = 24, color = "#B20000" }) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				opacity="0.34"
				d="M12 9V14"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12 21.41H5.93999C2.46999 21.41 1.01999 18.93 2.69999 15.9L5.81999 10.28L8.75999 5.00003C10.54 1.79003 13.46 1.79003 15.24 5.00003L18.18 10.29L21.3 15.91C22.98 18.94 21.52 21.42 18.06 21.42H12V21.41Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				opacity="0.34"
				d="M11.9945 17H12.0035"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const SuccessIcon: React.FC<IconProps> = ({ size = 24, color = "#22C55E" }) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				opacity="0.34"
				d="M7.75 12L10.58 14.83L16.25 9.16998"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const InfoIcon: React.FC<IconProps> = ({ size = 24, color = "#0EA5E9" }) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				opacity="0.34"
				d="M12 16V11"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				opacity="0.34"
				d="M11.9945 8H12.0035"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const CreateWalletIcon: React.FC<IconProps> = () => {
	return (
		<svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect width="70" height="70" rx="35" fill="#EFEFEF" />
			<path
				d="M52.6714 39.3922H45.2495C42.5244 39.3906 40.3155 37.1834 40.3138 34.4582C40.3138 31.7331 42.5244 29.5259 45.2495 29.5242H52.6714"
				stroke="#121212"
				strokeWidth="2.95935"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M46.089 34.3453H45.5176"
				stroke="#121212"
				strokeWidth="2.95935"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M27.2041 18.5H43.0504C48.3637 18.5 52.6711 22.8074 52.6711 28.1207V41.2786C52.6711 46.5919 48.3637 50.8993 43.0504 50.8993H27.2041C21.8908 50.8993 17.5834 46.5919 17.5834 41.2786V28.1207C17.5834 22.8074 21.8908 18.5 27.2041 18.5Z"
				stroke="#121212"
				strokeWidth="2.95935"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M25.8987 26.82H35.7968"
				stroke="#121212"
				strokeWidth="2.95935"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const LeftArrowIcon = () => {
	return (
		<svg width="35" height="34" fill="none">
			<g fill="#121212" clipPath="url(#a)">
				<path d="m27.036 16.741-5.14-5.13a.353.353 0 0 0-.515 0L19.84 13.15a5.44 5.44 0 0 1 1.182 5.932 5.44 5.44 0 0 1-1.182 1.766l1.541 1.54a.354.354 0 0 0 .516 0l5.139-5.132a.388.388 0 0 0 0-.515Zm-4.919-2.784 3.084 3.079-3.047 3.043a6.792 6.792 0 0 0-.037-6.122Z" />
				<path d="M22.385 15.966H7.135v1.504h15.25v-1.504Z" />
			</g>
			<defs>
				<clipPath id="a">
					<path fill="#fff" d="M7.135 11.5h20v11h-20z" />
				</clipPath>
			</defs>
		</svg>
	);
};

export const ReactivateWalletIcon = () => {
	return (
		<svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect width="70" height="70" rx="35" fill="#EFEFEF" />
			<path
				d="M52.6714 39.3922H45.2495C42.5244 39.3906 40.3155 37.1834 40.3138 34.4582C40.3138 31.7331 42.5244 29.5259 45.2495 29.5242H52.6714"
				stroke="#121212"
				strokeWidth="2.95935"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M46.089 34.3453H45.5176"
				stroke="#121212"
				strokeWidth="2.95935"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M27.2041 18.5H43.0504C48.3637 18.5 52.6711 22.8074 52.6711 28.1207V41.2786C52.6711 46.5919 48.3637 50.8993 43.0504 50.8993H27.2041C21.8908 50.8993 17.5834 46.5919 17.5834 41.2786V28.1207C17.5834 22.8074 21.8908 18.5 27.2041 18.5Z"
				stroke="#121212"
				strokeWidth="2.95935"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M25.8987 26.82H35.7968"
				stroke="#121212"
				strokeWidth="2.95935"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const LinkWalletIcon = () => {
	return (
		<svg width="71" height="70" viewBox="0 0 71 70" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect x="0.269928" width="70" height="70" rx="35" fill="#EFEFEF" />
			<path
				d="M36.1866 40.0417H31.6033C30.8516 40.0417 30.2283 39.4183 30.2283 38.6667C30.2283 37.915 30.8516 37.2917 31.6033 37.2917H36.1866C40.9899 37.2917 44.8949 33.3867 44.8949 28.5833C44.8949 23.78 40.9899 19.875 36.1866 19.875H27.0199C22.2166 19.875 18.3116 23.78 18.3116 28.5833C18.3116 30.6 19.0266 32.5616 20.3099 34.12C20.7866 34.7066 20.7133 35.5683 20.1266 36.0633C19.5399 36.54 18.6783 36.4667 18.1833 35.88C16.4783 33.8267 15.5433 31.2417 15.5433 28.5833C15.5433 22.2583 20.6766 17.125 27.0016 17.125H36.1683C42.4933 17.125 47.6266 22.2583 47.6266 28.5833C47.6266 34.9083 42.5116 40.0417 36.1866 40.0417Z"
				fill="#121212"
			/>
			<path
				opacity="0.4"
				d="M43.5199 52.875H34.3533C28.0283 52.875 22.8949 47.7417 22.8949 41.4167C22.8949 35.0917 28.0283 29.9583 34.3533 29.9583H38.9366C39.6883 29.9583 40.3116 30.5817 40.3116 31.3333C40.3116 32.085 39.6883 32.7083 38.9366 32.7083H34.3533C29.5499 32.7083 25.6449 36.6133 25.6449 41.4167C25.6449 46.22 29.5499 50.125 34.3533 50.125H43.5199C48.3233 50.125 52.2283 46.22 52.2283 41.4167C52.2283 39.4 51.5133 37.4384 50.2299 35.88C49.7533 35.2934 49.8266 34.4317 50.4133 33.9367C50.9999 33.4417 51.8616 33.5333 52.3566 34.12C54.0616 36.1733 54.9966 38.7583 54.9966 41.4167C54.9783 47.7417 49.8449 52.875 43.5199 52.875Z"
				fill="#121212"
			/>
		</svg>
	);
};
