import { createFileRoute } from "@tanstack/react-router";

import Login from "@/features/login";

export const Route = createFileRoute("/_auth/login")({
	component: LoginPage,
});

export default function LoginPage() {
	return <Login />;
}
