export const SET_MESSAGE = "SET_MESSAGE";
export const SET_PERCENTAGE = "SET_PERCENTAGE";
export const SET_DONE = "SET_DONE";
export const SET_CAPTURED_IMAGE = "SET_CAPTURED_IMAGE";
export const SET_FACE_STRUCTURE = "SET_FACE_STRUCTURE";
export const SET_SERVER_ERROR = "SET_SERVER_ERROR";
export const SET_SUCCESS = "SET_SUCCESS";
export const SET_SUBMITTING = "SET_SUBMITTING";
export const RESET = "RESET";

export type State = {
	message: string;
	percentage: number;
	done: boolean;
	capturedImage: string | null;
	faceStructure: {
		smiling: boolean;
	};
	serverError: string;
	success: string | null;
	isSubmitting: boolean;
};

export type Action =
	| { type: typeof SET_MESSAGE; payload: string }
	| { type: typeof SET_PERCENTAGE; payload: number }
	| { type: typeof SET_DONE; payload: boolean }
	| { type: typeof SET_CAPTURED_IMAGE; payload: string | null }
	| { type: typeof SET_FACE_STRUCTURE; payload: { smiling: boolean } }
	| { type: typeof SET_SERVER_ERROR; payload: string }
	| { type: typeof SET_SUCCESS; payload: string | null }
	| { type: typeof SET_SUBMITTING; payload: boolean }
	| { type: typeof RESET };
