import { createFileRoute, Navigate } from "@tanstack/react-router";

export const Route = createFileRoute("/")({
	component: Home,
});

export default function Home() {
	const token = JSON.parse(localStorage.getItem("rc-agent-creation") as string) as {
		token: string;
	};
	console.log("index", token);

	return <>{token ? <Navigate to="/welcome" /> : <Navigate to="/login" />}</>;
}
