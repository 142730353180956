import { useCallback, useReducer } from "react";
import { z } from "zod";
import formStateReducer from "@/states/formState/reducer";
import { FormData, State } from "@/utils/types";
import { handleBlur, handleChange, handleFormSubmit } from "@/states/formState/actions";

export const useFormUtils = <T extends FormData>(schema: z.ZodType<T>, initialState: State<T>) => {
	const [state, dispatch] = useReducer(formStateReducer, initialState);

	const handleSubmit = useCallback(
		(event: React.FormEvent<HTMLFormElement>, onSubmit: (formData: T) => Promise<void>) =>
			handleFormSubmit<T>(event, schema, state.formData as T, onSubmit, dispatch),
		[schema, state.formData],
	);

	const handleInputBlur = useCallback(
		(
			event: React.FocusEvent<HTMLInputElement>, // @ts-expect-error - TSCONVERSION
		) => handleBlur<T>(event, schema, state.formData as T, state.errors, dispatch),
		[schema, state.formData, state.errors],
	);

	const handleInputChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) =>
			handleChange<T>(event, dispatch, state.formData as T),
		[state.formData],
	);
	return {
		handleSubmit,
		handleBlur: handleInputBlur,
		handleChange: handleInputChange,
		state,
	};
};
