import React, { useEffect, useRef, useState } from "react";

interface Option {
  label: string;
  value: string;
}

interface CustomSelectProps {
  options: Option[];
  placeholder?: string;
  onChange: (value: string) => void;
  className?: string;
  label?: string;
  error?: string;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  options,
  placeholder = "Select an option",
  onChange,
  className,
  label,
  error,
}) => {
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const selectRef = useRef<HTMLDivElement>(null);

  const handleOptionClick = (value: string) => {
    setSelectedOption(value);
    setIsOpen(false);
    onChange(value);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="pb-5">
      {label && (
        <label
          className="block text-sm font-medium leading-6 text-copy-body"
          style={{ paddingBottom: 4 }}
        >
          {label}
        </label>
      )}
      <div ref={selectRef} className={`relative ${className}`}>
        <button
          type="button"
          className={`w-full px-4 text-sm py-2 text-left bg-white border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 ring-offset-2 ${
            error ? "border-red-700 ring-1 ring-offset-1 ring-red-700" : "border-gray-300"
          }`}
          onClick={() => setIsOpen(!isOpen)}
        >
          {selectedOption
            ? options.find((option) => option.value === selectedOption)?.label
            : placeholder}
        </button>
        {isOpen && (
          <ul className="absolute z-10 w-full text-sm mt-1 bg-white border border-gray-300 rounded-lg shadow-lg max-h-[300px] overflow-scroll">
            {options.map((option) => (
              <li
                key={option.value}
                className="px-4 py-2 hover:bg-pw-tangerine-01/50 cursor-pointer"
                onClick={() => handleOptionClick(option.value)}
              >
                {option.label}
              </li>
            ))}
          </ul>
        )}
      </div>
      {error && <p className="text-sm text-red-700 pt-1">{error}</p>}
    </div>
  );
};

export default CustomSelect;
