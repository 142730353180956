import { State } from "@/utils/types";
import {
  Action,
  SET_ERRORS,
  SET_FORM_DATA,
  SET_SERVER_ERROR,
  SET_SUBMITTING,
} from "./actionCreators";

function formStateReducer<T>(state: State<T>, action: Action<T>): State<T> {
  switch (action.type) {
    case SET_FORM_DATA:
      return { ...state, formData: action.payload };
    case SET_ERRORS:
      return { ...state, errors: action.payload };
    case SET_SERVER_ERROR:
      return { ...state, serverError: action.payload };
    case SET_SUBMITTING:
      return { ...state, isSubmitting: action.payload };
    default:
      return state;
  }
}

export default formStateReducer;
