import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { z } from "zod";
import { Errors } from "./types";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function Enum<T extends { [index: string]: string | number }>(baseEnum: T): Readonly<T> {
  return Object.freeze(
    new Proxy(baseEnum, {
      get(target: T, name: string | symbol) {
        if (!Object.prototype.hasOwnProperty.call(baseEnum, name)) {
          throw new TypeError(`Enum ${target} does not have property ${String(name)}`);
        }
      },
      set() {
        throw new TypeError("Enums are immutable");
      },
    }),
  ) as Readonly<T>;
}

export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const validateWithZod = <T>(schema: z.ZodSchema<T>, data: unknown): Errors | null => {
  const result = schema.safeParse(data);
  if (!result.success) {
    const errors: Errors = {};
    result.error.issues.forEach((issue) => {
      const path = issue.path.join(".");
      if (!errors[path]) {
        errors[path] = issue.message;
      }
    });
    return errors;
  }
  return null;
};

export const getErrorMessage = (error: unknown) => {
  let message: string;
  if (error instanceof Error) {
    message = error.message;
  } else if (error && typeof error === "object" && "message" in error) {
    message = String(error.message);
  } else if (typeof error === "string") {
    message = error;
  } else {
    message = "something went wrong";
  }
  return message;
};
