import { useAuth } from "@/authContext";
import { Button } from "@/components";
// import { useToken } from "@/hooks/useToken";
import { createFileRoute, Outlet, redirect, useNavigate, useRouter } from "@tanstack/react-router";
// import { useEffect } from "react";

export const Route = createFileRoute("/_main")({
  beforeLoad: ({ context }) => {
    // @ts-expect-error auth
    if (!context.auth.isAuthenticated) {
      throw redirect({
        to: "/login",
        replace: true,
      });
    }
  },
  component: MainLayout,
});

export default function MainLayout() {
  const auth = useAuth();
  const router = useRouter();
  const navigate = useNavigate();

  const handleLogout = () => {
    auth.logout().then(() => {
      router.invalidate().finally(() => {
        navigate({ to: "/" });
      });
    });
  };

  // const token = JSON.parse(localStorage.getItem("rc-agent-creation") as string);

  // // console.log("token", token);
  // const navigate = useNavigate();
  // useEffect(() => {
  //   if (!token) {
  //     navigate({ to: "/login" });
  //   }
  // }, [token, navigate]);
  return (
    <div className="flex flex-col items-end p-6 h-screen w-screen">
      <Button onClick={handleLogout} variant="outline" type="submit">
        Logout
      </Button>
      <div className="flex flex-col justify-center items-center h-full w-full">
        <img src="/logo.svg" alt="logo" />
        <Outlet />
      </div>
    </div>
  );
}
