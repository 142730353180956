"use client";

import * as React from "react";

type InputPropsWithoutPrefixSurfix = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  "prefix" | "suffix"
>;

export interface InputProps extends InputPropsWithoutPrefixSurfix {
  label?: string;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  error?: string;
  shadow?: boolean;
  disabled?: boolean;
}

export function Input({
  name,
  label,
  onFocus,
  onBlur,
  prefix,
  suffix,
  error,
  shadow,
  disabled,
  ...props
}: InputProps): JSX.Element {
  const [isFocused, setIsFocused] = React.useState(false);

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
    if (onFocus) {
      onFocus(event);
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    if (onBlur) {
      onBlur(event);
    }
  };

  return (
    <div className="pb-5">
      {label && (
        <label
          className="block text-sm font-medium leading-6 text-copy-body"
          style={{ paddingBottom: 4 }}
          htmlFor={name}
        >
          {label}
        </label>
      )}
      <div
        className={`flex gap-2 border text-sm rounded-md w-full py-3 px-4 ${
          isFocused ? "ring-indigo-500 ring-2 ring-offset-2" : "border-gray-300"
        } ${
          error
            ? "ring-red-700 ring-1 ring-offset-1 border-red-700"
            : shadow
            ? "border-gray-100"
            : "border-gray-300"
        } ${shadow && "shadow-sm"} ${
          disabled
            ? "bg-surface-disabled cursor-not-allowed text-copy-disabled"
            : "bg-white text-gray-950"
        }`}
      >
        {prefix}
        <input
          className="focus:outline-none w-full"
          onFocus={handleFocus}
          onBlur={handleBlur}
          type="text"
          name={name}
          id={name}
          disabled={disabled}
          {...props}
        />
        {suffix}
      </div>
      {error && <p className="text-sm text-red-700 pt-1">{error}</p>}
    </div>
  );
}

Input.displayName = "Input";
