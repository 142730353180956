import LivenessCheck from "@/features/livenessCheck";
import { createFileRoute, useRouteContext } from "@tanstack/react-router";

export const Route = createFileRoute("/_main/liveness-check")({
	component: LivenessCheckPage,
});

export default function LivenessCheckPage() {
	const context = useRouteContext({ strict: false });
	// @ts-expect-error auth
	return <LivenessCheck token={context.auth.user} />;
}
