import { useCallback, useEffect, useState } from "react";

// export const useVideoCapture = () => {
//   const [video, setVideo] = useState<string | null>(null);
//   const [isVideoReady, setIsVideoReady] = useState(false);

//   const startVideoCapture = useCallback(() => {
//     const captureVideo = async () => {
//       if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
//         const stream = await navigator.mediaDevices.getUserMedia({
//           video: {
//             width: { ideal: 640 }, // Lower resolution to reduce size
//             height: { ideal: 360 },
//           },
//         });
//         const mediaRecorder = new MediaRecorder(stream, {
//           videoBitsPerSecond: 1000000,
//           mimeType: "video/webm",
//         });

//         const recordedChunks: BlobPart[] = [];

//         mediaRecorder.addEventListener("dataavailable", (event) => {
//           if (event.data.size > 0) {
//             recordedChunks.push(event.data);
//           }
//         });

//         mediaRecorder.addEventListener("stop", async () => {
//           const blob = new Blob(recordedChunks, { type: "video/webm" });
//           const reader = new FileReader();
//           reader.onloadend = () => {
//             setVideo(reader.result as string);
//             setIsVideoReady(true);
//           };
//           reader.readAsDataURL(blob);
//         });

//         mediaRecorder.start();
//         setTimeout(() => mediaRecorder.stop(), 10000);
//       }
//     };

//     captureVideo();
//   }, []);
//   return { video, isVideoReady, startVideoCapture };
// };

export const useVideoCapture = () => {
  const [video, setVideo] = useState<string | null>(null);
  const [isVideoReady, setIsVideoReady] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const startVideoCapture = useCallback(() => {
    const captureVideo = async () => {
      try {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: {
              width: { ideal: 640 },
              height: { ideal: 360 },
            },
          });

          const mediaRecorder = new MediaRecorder(stream, {
            videoBitsPerSecond: 1000000,
            mimeType: "video/webm",
          });

          const recordedChunks: BlobPart[] = [];

          mediaRecorder.addEventListener("dataavailable", (event) => {
            if (event.data.size > 0) {
              recordedChunks.push(event.data);
            }
          });

          mediaRecorder.addEventListener("stop", async () => {
            const blob = new Blob(recordedChunks, { type: "video/webm" });
            const reader = new FileReader();
            reader.onloadend = () => {
              setVideo(reader.result as string);
              setIsVideoReady(true);
            };
            reader.readAsDataURL(blob);
          });

          mediaRecorder.start();
          setTimeout(() => mediaRecorder.stop(), 10000);
        } else {
          throw new Error("getUserMedia is not supported on this device");
        }
      } catch (err) {
        console.error("Error capturing video:", err);
        setError(err instanceof Error ? err.message : "An unknown error occurred");
      }
    };

    captureVideo();
  }, []);

  useEffect(() => {
    // Check for media devices support when component mounts
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      setError("Your device doesn't support video capture");
    }
  }, []);

  return { video, isVideoReady, startVideoCapture, error };
};
